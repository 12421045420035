import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alerts', {
  state: () => ({
    showAlert: false,
    alertType: 'primary',
    message: '',
    description: '',
  }),

  actions: {
    setMessage(alertType, message, description = '', delay = 5000) {
      this.message = message;
      this.description = description;
      this.alertType = alertType;
      this.showAlert = true;

      this.delayCloseAlert(delay);
    },

    closeAlert() {
      this.showAlert = false;
      this.message = '';
      this.description = '';
    },

    delayCloseAlert(delay) {
      setTimeout(this.closeAlert, delay);
    },
  },
});
