<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: true,
  },
  colour: {
    type: String,
    required: true,
  },
  removeable: {
    type: Boolean,
    default: false,
  },
  showTag: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['remove']);
</script>

<template>
  <div
    class="inline-flex items-center gap-x-0.5 overflow-hidden rounded-full px-2 pb-[3px] pt-[1px] ring-1 ring-slate-900/05 ring-inset"
    :style="`background-color: ${props.colour}`"
  >
    <span
      v-if="showTag"
      class="pl-[7px]"
    >
      <i-fluent-tag-16-regular class="h-4 w-4" />
    </span>
    <span
      :class="removeable ? '' : 'px-1'"
      class="truncate whitespace-nowrap text-sm"
    >
      {{ tag }}
    </span>
    <button
      v-if="removeable"
      class="group relative ml-0 h-7 w-6 flex items-center justify-center rounded-sm transition -m-2 hover:bg-slate-500/10"
    >
      <i-heroicons-outline:x
        class="mt-[2px] h-3.5 w-3.5 opacity-50 transition group-hover:opacity-100"
        @click.prevent="emits('remove')"
      />
    </button>
  </div>
</template>
