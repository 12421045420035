import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { useAlertStore } from '@/stores/alertStore';
import router from '@/router';

const baseURL = import.meta.env.VITE_API_BASE_URL;
const csrfCookieName = import.meta.env.VITE_CSRF_COOKIE_NAME;

const cmApi = axios.create({
  baseURL: `${baseURL}/api/v3`,
  withCredentials: true,
  withXSRFToken: true,
  timeout: 10000,
  xsrfCookieName: csrfCookieName,
  xsrfHeaderName: 'X-CSRF-Token',
  headers: {
    'Access-Control-Allow-Origin': import.meta.env.VITE_API_BASE_URL,
  },
});

function setupInterceptor() {
  const alertStore = useAlertStore();
  cmApi.interceptors.response.use(response => response, (error) => {
    if (error.response?.status === 401) {
      const returnTo = encodeURIComponent(window.location.href);
      document.location.href = `${baseURL}/users/sign_in?layout=desktop&return_to=${returnTo}`;
    }
    else if (['deactivated', 'expired'].includes(error.response?.data.error)) {
      const errorData = error.response.data;
      router.push({ path: `/teams/${error?.response?.data?.organization_team_id}/account_${errorData.error}` });
    }
    else {
      console.error(error?.response?.data || error);
      if (import.meta.env.PROD) {
        const errorObject = new Error(error?.response?.data?.error_message || error);
        errorObject.name = error?.response?.data?.error || 'Unknown Error';
        // @ts-expect-error - this is a workaround to comply with sentry code
        errorObject.prototype = Error.prototype;
        Sentry.captureException(errorObject);
      }
      alertStore.setMessage('danger', error?.response?.data?.error_message || 'Something went wrong, please contact support');
    }
    return Promise.reject(error);
  });
}

export { cmApi, setupInterceptor };
