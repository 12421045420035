<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import { useHeadTitle } from '../composables/headTitle';
import { useRouterLink } from '@/composables/router';
import { useUserStore } from '@/stores/userStore';

const smsCampaignsIndexLink = useRouterLink('SmsCampaignsIndex');
const smsCampaignsNewLink = useRouterLink('SmsCampaignsNew');
const smsContactsLink = useRouterLink('SmsContacts');
const routeComp = useRoute();
const title = useHeadTitle();
const userStore = useUserStore();

useHead({ title });
</script>

<template>
  <router-link
    :to="smsCampaignsIndexLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[{ 'border-primary-500 text-gray-900': routeComp.name === 'SmsCampaignsIndex' }]"
    aria-current="page"
  >
    Analytics
  </router-link>
  <router-link
    :to="smsCampaignsNewLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[{ 'border-primary-500 text-gray-900': routeComp.name === 'SmsCampaignsNew' }]"
    aria-current="page"
  >
    Compose & Send
  </router-link>
  <router-link
    v-if="!userStore.smsTrialUser"
    :to="smsContactsLink"
    class="inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium" :class="[{ 'border-primary-500 text-gray-900': routeComp.name === 'SmsContacts' }]"
    aria-current="page"
  >
    Directory
  </router-link>
</template>
