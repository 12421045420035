<script setup lang="ts">
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

export interface Props {
  /** Label appears beside toggle */
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
});

const model = defineModel<boolean>({ required: true });

const disabledClasses = ['!bg-neutral-500', '!cursor-default', '!pointer-events-none'];
</script>

<template>
  <SwitchGroup
    as="div"
    class="flex items-center"
  >
    <Switch
      v-model="model" class="group relative h-5 w-10 inline-flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      :class="[
        modelValue ? 'bg-primary-500' : 'bg-slate-200',
        $attrs.disabled ? disabledClasses : '',
      ]"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none absolute h-full w-full rounded-md bg-white"
      />
      <span
        aria-hidden="true"
        class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out" :class="[
          modelValue ? 'bg-primary-500' : 'bg-slate-200',
          $attrs.disabled ? disabledClasses : '',
        ]"
      />
      <span
        aria-hidden="true"
        class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform border border-slate-200 rounded-full bg-white shadow ring-0 transition-transform duration-200 ease-in-out" :class="[modelValue ? 'translate-x-5' : 'translate-x-0']"
      />
    </Switch>
    <SwitchLabel
      as="span"
      class="ml-3"
    >
      <slot name="left-icon" />
      <span class="text-base text-gray-900 font-medium">{{ props.label }}</span>
    </SwitchLabel>
  </SwitchGroup>
</template>
