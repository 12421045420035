<script setup lang="ts">
import { type VariantProps, tv } from 'tailwind-variants';

export type alertTypesForRealVariants = VariantProps<typeof alertVariants>;
const props = defineProps<alertPropsType>();

const emits = defineEmits(['closeAlert']);

interface alertPropsType {
  show: boolean;
  alertType: alertTypesForRealVariants['alertType'];
  message: string;
  description: string | null;
}

const close = () => emits('closeAlert', false);

const alertVariants = tv({
  base: 'h-6 w-6',
  variants: {
    alertType: {
      primary: 'i-heroicons:check-circle-20-solid text-green-500',
      danger: 'i-ph:warning-fill text-red-500',
      info: 'i-heroicons:exclamation-circle-20-solid text-primary-500',
      warning: 'i-ph:warning-fill text-yellow-500',
    },
  },
});
</script>

<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-start justify-center px-4 py-6 sm:p-20"
  >
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="-translate-y-2 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="-translate-y-2 opacity-0"
    >
      <div
        v-if="show"
        class="pointer-events-auto max-w-2xl w-full overflow-hidden rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <div id="alert-icon" :class="alertVariants({ alertType: props.alertType })" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm text-slate-600 font-semibold">
                {{ message }}
              </p>
              <div v-if="description" class="mt-2.5 text-sm text-slate-700" data-testid="alert-description">
                <p>{{ description }}</p>
              </div>
              <!-- <p class="mt-1 text-sm text-slate-500">
                This can be a slot for optional line 2.
              </p> -->
            </div>
            <div class="ml-4 flex flex-shrink-0 pt-0.5">
              <button
                type="button"
                class="inline-flex rounded-md bg-white text-slate-400 transition focus:bg-slate-100 hover:text-slate-500"
                @click="close"
              >
                <span class="sr-only">Close</span>
                <span class="i-heroicons:x-mark-20-solid h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
